<template>
  <div>
    <header-banner
      banner-img="https://static.moegoat.com/images/banner/banner-2.png"
      title="热门库"
      :sub-title="currentTab"
    />

    <posts-list
      :data="query"
      @currentTab="currentMode"
      currentPage="popular"
      :cols="[4]"
      :max_tags="5"
      :max_tags_length="15"
    />
    <back-to-top />
  </div>
</template>
<script>
import HeaderBanner from "@/components/HeaderBanner.vue";
import PostsList from "@/components/PostsList.vue";
import backToTop from "@/components/backToTop.vue";

export default {
  name: "Popular",
  components: {
    HeaderBanner,
    PostsList,
    backToTop
  },
  head() {
    return {
      title: "LoiBus-热门"
    };
  },
  data() {
    return {
      currentTab: "本周最热",
      query: [
        {
          tab: "本周最热",
          api: "lois/hottest/weekly"
        },
        {
          tab: "本月最热",
          api: "lois/hottest/monthly"
        },
        {
          tab: "历史最热",
          api: "lois/hottest/all"
        }
      ]
    };
  },
  methods: {
    currentMode(val) {
      this.currentTab = val;
    }
  }
};
</script>

