<template>
  <div>
    <!-- 页面子菜单 -->
    <v-tabs class="SecondaryNav" v-model="tabIndex" slider-color="loi" color="loi" fixed-tabs>
      <v-tab v-for="(item,index) in tabsData" :key="index">{{item.tab}}</v-tab>
    </v-tabs>
    <!-- 内容列表 -->
    <v-container
      :class="{'px-5 pt-2':$vuetify.breakpoint.xsOnly,'px-7': $vuetify.breakpoint.smAndUp && !showLoiModal,'pl-7 pr-11':$vuetify.breakpoint.smAndUp && showLoiModal}"
      fluid
    >
      <v-row>
        <v-col
          v-for="(loi,index) in list"
          :key="index"
          :data-num="index + 1"
          :cols="cols[3] || 12"
          :sm="cols[2] || 6"
          :md="cols[1] || 4"
          :lg="cols[0]"
        >
          <v-hover>
            <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" class="mx-auto">
              <v-img
                id="cover-img"
                :aspect-ratio="16/9"
                :lazy-src="loi.cover"
                :src="loi.cover"
                @click.stop="openLoiModal(loi.id)"
              >
                <!-- 封面顶部-->

                <!-- top标记 -->
                <v-img
                  :src="`https://static.moegoat.com/images/popular/top${index + 1}.png`"
                  :lazy-src="`https://static.moegoat.com/images/popular/top${index + 1}.png`"
                  v-if="index < 3 && $route.name === 'popular'"
                  style="position:absolute;left:15px;top:15px;"
                  width="60"
                ></v-img>
                <div class="d-flex flex-row justify-end">
                  <!-- hover信息块（精选/视频数量/图片数） -->
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-column">
                      <!-- 精选 -->
                      <v-slide-x-transition>
                        <v-img
                          contain
                          width="50"
                          class="ma-3"
                          src="https://static.moegoat.com/images/choicest-mark.png"
                          v-if="loi.choicest == 1 && !hover"
                        ></v-img>
                      </v-slide-x-transition>
                      <!-- 热门 -->
                      <v-slide-x-transition>
                        <v-img
                          contain
                          width="42"
                          :class="{'ma-4':loi.choicest == 0 , 'ml-4 mt-n1':loi.choicest == 1}"
                          src="https://static.moegoat.com/images/hot-mark.png"
                          v-if="loi.viewer_count >= 8000 && !hover"
                        ></v-img>
                      </v-slide-x-transition>
                    </div>

                    <v-scroll-x-reverse-transition>
                      <div class="ma-4" v-show="hover" style="position:absolute;right:0px">
                        <!-- 热门 -->
                        <div v-show="loi.viewer_count >= 8000">
                          <v-btn
                            height="40px"
                            width="80px"
                            rounded
                            class="ml-4 mb-3"
                            style="transform:translateX(1px)"
                            small
                            color="#EF5350"
                            dark
                            translate
                          >
                            <v-icon style="transform:translateX(2px)">mdi-fire</v-icon>
                            <span class="ml-2">热门</span>
                          </v-btn>
                        </div>

                        <!-- 精选 -->
                        <v-btn
                          height="40px"
                          width="80px"
                          rounded
                          class="ml-4 mb-3"
                          style="transform:translateX(1px)"
                          small
                          color="indigo"
                          dark
                          :class="{'d-none':loi.choicest == 0}"
                          translate
                        >
                          <v-icon style="transform:translateX(2px)">mdi-star-circle</v-icon>
                          <span class="ml-2">精选</span>
                        </v-btn>

                        <!-- 视频数量 -->
                        <div v-show="loi.video_count">
                          <v-btn
                            height="40px"
                            width="80px"
                            rounded
                            class="ml-4 d-block mb-3"
                            small
                            color="primary"
                          >
                            <v-icon
                              :style="{'transform': loi.video_count.toString().length === 2 ? 'translateX(3px)' : 'translateX(-5px)'}"
                            >mdi-video</v-icon>
                            <span
                              class="ml-2 body-2 font-weight-bold"
                              style="transform:translate(-2px,1px)"
                              v-text="loi.video_count"
                            ></span>
                          </v-btn>
                        </div>

                        <!-- 图片数量 -->
                        <div v-show="loi.pic_count">
                          <v-btn
                            rounded
                            height="40px"
                            width="80px"
                            class="ml-4 d-block mb-3"
                            style="transform:translateX(1px)"
                            small
                            color="bpink"
                            dark
                          >
                            <v-icon>mdi-image</v-icon>
                            <span
                              class="ml-1 body-2 font-weight-bold"
                              style="transform:translateY(1px)"
                              v-text="loi.pic_count"
                            ></span>
                          </v-btn>
                        </div>
                      </div>
                    </v-scroll-x-reverse-transition>
                  </div>
                </div>
                <!-- Loi封面loading -->
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <!-- Loi信息块 -->
              <v-row class="justify-space-between mx-1">
                <v-col cols="9" class="flex-column">
                  <span
                    class="subtitle-1 font-weight-bold d-inline-block text-truncate cursor-pointer blue-grey--text text--darken-3"
                    style="max-width: 100%"
                    @click.stop="openLoiModal(loi.id)"
                  >{{loi.loi_title}}</span>
                  <div>
                    <v-chip label small class="font-weight-light mr-2">
                      <v-icon small class="mr-1">mdi-clock-outline</v-icon>
                      {{formatTime(loi.date)}}
                    </v-chip>
                    <v-chip
                      small
                      label
                      :color="tag.color"
                      text-color="white"
                      class="mr-2 hidden-lg-and-down"
                      v-for="tag in loi.tags.slice(0,loi_tags(loi.tags,formatTime(loi.date).length))"
                      :key="tag.id"
                      :to="{ name: 'tag-page', params: { id: tag.id }}"
                    >{{tag.name}}</v-chip>
                    <v-hover v-slot:default="{ hover }" close-delay="50">
                      <div class="d-inline-block" style="position:relative;">
                        <v-chip
                          ripple
                          label
                          small
                          v-show="loi.tags.length > loi_tags(loi.tags,formatTime(loi.date).length)  || $vuetify.breakpoint.xsOnly"
                          style="width:25px;padding-left:5px"
                        >
                          <v-icon small>mdi-dots-vertical</v-icon>
                        </v-chip>
                        <v-slide-y-transition>
                          <v-sheet
                            v-show="hover"
                            style="position:absolute;top:30px;z-index:20"
                            elevation="5"
                            width="68px"
                            class="pa-2"
                          >
                            <div class="d-flex flex-column">
                              <!-- PC -->
                              <div v-if="$vuetify.breakpoint.smAndUp">
                                <v-btn
                                  small
                                  v-for="tag in loi.tags.slice(loi_tags(loi.tags,formatTime(loi.date).length))"
                                  :key="tag.id"
                                  :color="tag.color"
                                  dark
                                  width="100%"
                                  height="25px"
                                  :to="{ name: 'tag-page', params: { id: tag.id }}"
                                  class="tag_folded"
                                >{{tag.name}}</v-btn>
                              </div>

                              <!-- 移动端 -->
                              <div v-else>
                                <v-btn
                                  small
                                  v-for="tag in loi.tags"
                                  :key="tag.id"
                                  :color="tag.color"
                                  dark
                                  width="100%"
                                  height="25px"
                                  :to="{ name: 'tag-page', params: { id: tag.id }}"
                                  class="tag_folded"
                                >{{tag.name}}</v-btn>
                              </div>
                            </div>
                          </v-sheet>
                        </v-slide-y-transition>
                      </div>
                    </v-hover>
                  </div>
                </v-col>
                <div class="d-flex align-self-center mr-3" style="position:absolute;right:4px;">
                  <div class="flex-column mr-2" style="transform:translate(-5px,-4px)">
                    <!-- downloader -->
                    <loi-downloader
                      :theme="0"
                      :loi_id="loi.id"
                      :open_key="loi.open_key"
                      :download_count="loi.download_count"
                      :vip_requirement="loi.vip_requirement"
                      :is_downloaded="loi.is_downloaded"
                      @download_increase="download_increase(index)"
                    ></loi-downloader>
                  </div>
                  <div class="flex-column" style="transform:translate(0,-4px)">
                    <loi-liker
                      :theme="0"
                      :loi_id="loi.id"
                      :like_count="loi.like_count"
                      :is_liked="loi.is_liked"
                    ></loi-liker>
                  </div>
                </div>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

    <!-- 无限加载 -->
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
      <div slot="spinner" class="my-8">
        <v-row justify="center">
          <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </v-row>
      </div>
      <div slot="no-more" class="mb-6 mt-3" v-show="list.length > 8">😊 到底啦</div>
      <div slot="no-results" class="my-5" style="transform:translateY(40%)">
        <v-img
          contain
          class="mx-auto"
          width="250"
          lazy-src="https://static.moegoat.com/images/nodata-bus.png"
          src="https://static.moegoat.com/images/nodata-bus.png"
        ></v-img>
        <div class="title" style="transform:translateY(40px)">📦 这里是空的</div>
      </div>
      <div slot="error" slot-scope="{ trigger }" class="my-5">
        ⚠️ 加载失败 , 请
        <v-btn @click="trigger">重新加载</v-btn>
      </div>
    </infinite-loading>

    <!-- 弹出层 -->
    <post-content-popup
        v-show="showLoiModal"
        @hideLoiModal="showLoiModal = false"
        ref="loi_popup"
    />

  </div>
</template>
<script>
import axios from "axios";
import PostContentPopup from "@/components/PostContentPopup.vue";
import { baseURL } from "@/config.js";
import LoiDownloader from "@/components/LoiDownloader.vue"; //下载组件
import LoiLiker from "@/components/LoiLiker.vue"; //喜欢组件
import { formatTime, formatDate } from "@/utils/index.js";

export default {
  name: "PostsList",
  props: {
    data: Array,
    cols: {
      type: Array,
      default: () => [3, 4, 6, 12],
      required: false
    },
    currentPage: {
      type: String,
      default: "",
      required: false
    },
    max_tags: {
      type: Number,
      default: 3,
      required: false
    },
    max_tags_length: {
      type: Number,
      default: 11,
      required: false
    }
  },
  components: {
    PostContentPopup,
    LoiDownloader, //下载组件
    LoiLiker //喜欢组件
  },
  data() {
    return {
      showLoiModal: false,
      loiID: null,
      page: 1,
      list: [],
      tabsData: this.data,
      infiniteId: +new Date(),
      tabIndex: 0,
      props: {
        fixedTabs: false
      }
    };
  },
  methods: {
    infiniteHandler($state) {
      axios
        .get(baseURL + this.tabsData[this.tabIndex].api, {
          params: {
            page: this.page
          }
        })
        .then(({ data }) => {
          this.$emit("total", data.pagination.total);
          if (data.data.length) {
            this.page += 1;
            this.list.push(...data.data);
            // window.console.log(this.list);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
    changeTab(currentTab) {
      this.page = 1;
      this.list = [];
      this.infiniteId += 1;
      // history.pushState({}, null, `?list=${this.tabsData[this.tabIndex].path}`);
      this.$emit("currentTab", currentTab);
    },
    openLoiModal(id) {
      this.showLoiModal = true;
      this.$refs.loi_popup.fetch_Loi(id);

      // let event = window.event || arguments.callee.caller.arguments[0];
      // let content_dom = [
      //   ...document.querySelectorAll(".loi-category"),
      //   ...document.querySelectorAll(".star-loi")
      // ];
      //
      // if (content_dom) {
      //   if (!content_dom.includes(event.target)) {
      //     this.loiID = id;
      //     this.showLoiModal = true;
      //   }
      // }
      // return 0;
    },
    formatTime(date) {
      return formatTime(formatDate(date));
    },
    loi_tags(tags, dateLen) {
      const max = this.max_tags;
      if (tags.length < max) {
        return tags.length;
      }
      let len = 0;
      for (const tag of tags.slice(0, max)) {
        len += tag.name.length;
      }

      len += dateLen;

      if (len > this.max_tags_length) {
        return max - 1;
      }
      return max;
    },
    //下载组件下载量增加emit响应事件
    download_increase(index) {
      this.list[index].download_count++;
    }
  },
  watch: {
    tabIndex(val) {
      this.changeTab(this.tabsData[val].tab);
    },
    data: {
      handler: function() {
        this.tabsData = this.data;
        this.page = 1;
        this.list = [];
        this.infiniteId += 1;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
#cover-img {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.SecondaryNav {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
}

.tag_folded:not(:last-child) {
  margin-bottom: 13px;
}
</style>
